import Vue from 'vue'
import Vuex from 'vuex'
import cart from './cart'
import user from './user'
import club from './club'
import notice from './notice'
import feedback from './feedback'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: 0,
    registrationFormData: false,
    isRedirecting: false,
    isRedirectingTo: false,
    inviteData: false,
    isInIFrame: false,
    isEmbedded: false,
    hideAside: false
  },
  getters: {
    isLoading (state) {
      return state.loading > 0
    },
    isEmbedded (state) {
      return state.isEmbedded
    },
    hideAside (state) {
      return state.hideAside
    }
  },
  mutations: {
    increaseLoading (state) {
      state.loading++
    },
    decreaseLoading (state) {
      state.loading = Math.max(0, state.loading - 1)
    },
    setRegistrationFormData (state, data) {
      state.registrationFormData = data
    },
    setIsRedirecting (state, data) {
      if (data) {
        state.loading++
      } else {
        state.loading = Math.max(0, state.loading - 1)
      }
      state.isRedirecting = data

      if (data === false) state.isRedirectingTo = null
    },
    setIsRedirectingTo (state, url) {
      state.isRedirectingTo = url
    },
    setInviteData (state, data) {
      state.inviteData = data
    },
    setIsInIFrame (state, data) {
      state.isInIFrame = data
    },
    setIsEmbedded (state, data) {
      state.isEmbedded = data
    },
    setHideAside (state, data) {
      state.hideAside = data
    }
  },
  modules: {
    cart,
    user,
    club,
    notice,
    feedback
  }
})
