const club = {
  namespaced: true,
  state: () => {
    return {
      club: false,
      clubs: false,
      previousClub: false
    }
  },
  getters: {
    club (state) {
      return state.club
    },
    clubs (state) {
      return state.clubs
    },
    isDisplayAgeRestriction (state) {
      return parseInt(state.club.public_display_age_restriction) > 0
    },
    showOccupation (state) {
      return state.club.public_occupation_display !== 'hidden'
    },
    showOccupationFrom (state) {
      return state.club.public_occupation_display === 'amount' && parseInt(state.club.public_occupation_display_amount)
    },
    overviewDisplay (state) {
      return state.club.public_overview_display ?? 'grid'
    },
    calculateAgeOnStartActivity (state) {
      return state.club.ageCalculation === 'calculateAgeOnStartActivity'
    },
    isBlocked (state) {
      if (!state.club || !state.clubs) return false
      const club = state.clubs[state.club.nid]
      if (!club) return false
      return club.blocked
    }
  },
  mutations: {
    setClub (state, club) {
      state.club = club
    },
    setClubs (state, clubs) {
      state.clubs = clubs
    }
  },
  actions: {
    setClub ({ state, commit, rootState, getters, rootGetters, dispatch }, club) {
      if (club === state.club || parseInt(club.nid) === parseInt(state.club.nid)) return false
      if (rootGetters['cart/cart'] && rootGetters['cart/cart']?.items.length) {
        state.previousClub = club
        return dispatch('cart/switch', null, { root: true })
      }

      state.previousClub = false

      commit('setClub', club)
    }
  }
}

export default club
